<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postDepartmentData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Name *"
            label-for="name"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <b-form-input
                id="name"
                v-model="editData.name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="church"
          >
            <b-form-group
              label="Church *"
              label-cols-md="4"
              :state="errors.length > 0 ? false:null"
            >

              <v-select
                v-model="editData.church"
                placeholder="Please Select Church"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="churchData"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BButton,
    // BFormRadio,
    // BCardText,
    // BFormTextarea,
    // BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      churchData: [],
      // departmentData: [],
      // positionData: [],
      editData: {},
      required,
    }
  },
  mounted() {
    this.getDepartmentData()
    this.getChurchData()
    // this.getPositionData()
  },
  methods: {
    getChurchData() {
      axios
        .get('/churches')
        .then(res => {
          this.churchData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getDepartmentData() {
      axios
        .get(`/departments/${this.$route.params.id}`)
        .then(res => {
          this.editData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    postDepartmentData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            this.editData.church_id = this.editData.church.id,
            delete this.editData.church,
            axios.post(`/departments/${this.$route.params.id}`, this.editData).then(res => {
              this.edit = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'department-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.5rem;
}
</style>
